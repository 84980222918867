<template>
  <div id="app">
    <AudioPlayer />
  </div>
</template>

<script>
import AudioPlayer from './components/AudioPlayer.vue';

export default {
  components: {
    AudioPlayer,
  },
};
</script>
