<template>
    <div class="audio-player">
      <!-- Title Bar -->
      <header class="title-bar">
        <h1>{{ title }}</h1>
      </header>
  
      <!-- Waveform Container -->
      <div id="waveform"></div>
  
      <!-- Playback Controls -->
      <div class="controls">
        <button @click="playPause">{{ isPlaying ? 'Pause' : 'Play' }}</button>
        <button @click="stop">Stop</button>
      </div>
  
      <!-- Volume Control -->
      <div class="volume-control">
        <label for="volume">Volume: {{ Math.round(volume * 100) }}%</label>
        <input
          type="range"
          id="volume"
          min="0"
          max="1"
          step="0.01"
          v-model="volume"
          @input="changeVolume"
        />
      </div>
  
      <!-- Timer Display -->
      <div class="timer">
        {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
      </div>
  
      <!-- Metadata Toggle and Display -->
      <div class="metadata-container">
        <button @click="toggleMetadata">{{ showMetadata ? 'Hide Metadata' : 'Show Metadata' }}</button>
        <div v-if="showMetadata" class="metadata">
          <p><strong>Title:</strong> {{ metadata.title || 'Unknown' }}</p>
          <p><strong>Artist:</strong> {{ metadata.artist || 'Unknown' }}</p>
          <p><strong>Album:</strong> {{ metadata.album || 'Unknown' }}</p>
          <!-- Add more metadata fields as needed -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import WaveSurfer from 'wavesurfer.js';
  import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js';
  
  export default {
    data() {
      return {
        title: 'ENNES Audio Player',
        wavesurfer: null,
        isPlaying: false,
        isLooping: false,
        loopRegion: null,
        currentTime: 0,
        duration: 0,
        showMetadata: false,
        metadata: {},
        volume: 1, // Default volume at 100%
      };
    },
    mounted() {
      this.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#00bcd4', // Cyan wave color
        progressColor: '#008ba3', // Darker cyan for progress
        backgroundColor: '#121212', // Dark background
        cursorColor: '#FFFFFF', // White cursor
        height: 150,
        responsive: true,
        plugins: [
          RegionsPlugin.create({
            regions: [],
            dragSelection: {
              slop: 5,
            },
          }),
        ],
      });
  
      // Load the audio file from assets
      const audioPath = require('@/assets/weareback.mp3');
      this.wavesurfer.load(audioPath);
  
      // Set initial volume
      this.wavesurfer.setVolume(this.volume);
  
      // Update duration once ready
      this.wavesurfer.on('ready', () => {
        this.duration = this.wavesurfer.getDuration();
      });
  
      // Update currentTime during playback
      this.wavesurfer.on('audioprocess', () => {
        this.currentTime = this.wavesurfer.getCurrentTime();
      });
  
      // Handle loop functionality
      this.wavesurfer.on('region-updated', (region) => {
        if (this.isLooping && region.id === 'loop') {
          this.loopRegion = region;
        }
      });
  
      this.wavesurfer.on('region-created', (region) => {
        // Remove previous loop region if any
        if (this.loopRegion && region.id !== 'loop') {
          this.loopRegion.remove();
        }
        // Assign the new region as loop region
        if (this.isLooping) {
          region.update({ id: 'loop', loop: true, color: 'rgba(0, 188, 212, 0.3)' });
          this.loopRegion = region;
        } else {
          region.remove();
        }
      });
  
      // Loop playback when it reaches the end of the region
      this.wavesurfer.on('region-out', (region) => {
        if (this.isLooping && region.id === 'loop') {
          this.wavesurfer.play(region.start);
        }
      });
  
      // Fetch metadata if available (placeholder logic)
      this.fetchMetadata();
    },
    methods: {
      playPause() {
        this.wavesurfer.playPause();
        this.isPlaying = !this.isPlaying;
      },
      stop() {
        this.wavesurfer.stop();
        this.isPlaying = false;
      },
      toggleMetadata() {
        this.showMetadata = !this.showMetadata;
      },
      formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
      },
      fetchMetadata() {
        // Placeholder for fetching metadata logic
        // You can use an ID3 library to extract metadata from the audio file
        // For now, we'll use hardcoded values
        this.metadata = {
          title: 'We Are Back',
          artist: 'HYENA HEX',
          album: 'Working Title',
        };
      },
      changeVolume() {
        this.wavesurfer.setVolume(this.volume);
      },
    },
    watch: {
      isLooping(newVal) {
        if (newVal) {
          // Enable drag selection for loop region
          this.wavesurfer.enableDragSelection({});
        } else {
          // Disable drag selection
          this.wavesurfer.disableDragSelection();
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .audio-player {
    background-color: #1e1e1e; /* Dark background */
    color: #ffffff; /* White text */
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .title-bar {
    margin-bottom: 20px;
  }
  
  .title-bar h1 {
    margin: 0;
    font-size: 36px;
    color: #ffffff; /* Cyan title color */
  }
  
  #waveform {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #333;
    border-radius: 4px;
  }
  
  .controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
  
  button {
    background-color: #00bcd4; /* Cyan background */
    color: #ffffff; /* White text */
    border: none;
    padding: 10px 20px;
    margin: 5px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #008ba3; /* Darker cyan on hover */
  }
  
  .timer {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .volume-control {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .volume-control label {
    display: block;
    margin-bottom: 5px;
  }
  
  .volume-control input[type='range'] {
    width: 200px;
  }
  
  .metadata-container {
    margin-top: 20px;
  }
  
  .metadata {
    background-color: #2e2e2e;
    padding: 10px;
    border-radius: 4px;
    text-align: left;
    display: inline-block;
    max-width: 100%;
  }
  
  .metadata p {
    margin: 5px 0;
  }
  
  @media (max-width: 600px) {
    .controls {
      flex-direction: column;
    }
  
    button {
      width: 100%;
      margin: 5px 0;
    }
  }
  </style>
  